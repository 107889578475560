import React from 'react';
import Layout from '../components/layout';
import FormCalendly from '../components/contact/form-calendly';
import OfficeListing from '../components/contact/office-listing';

const headMeta = {
	title: 'Contact Us | Pandev Law',
	description:
		'Do you have a question for a Business or Immigration Lawyer? Contact us here direclty to schedule a consultation with a trusted business or immigration attorney.',
	keywords: '',
};

const schema = `{}`;

function Contact({ location }) {
	return (
		<Layout headMeta={headMeta} location={location} footerSchema={schema}>
			<FormCalendly />
			<OfficeListing />
		</Layout>
	);
}

export default Contact;

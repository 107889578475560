import React, { useReducer } from 'react';
import classnames from 'classnames';
import emailjs from 'emailjs-com';
import { navigate } from 'gatsby';
import get from 'lodash.get';

const EMAIL_REGEX =
  // eslint-disable-next-line
  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/g;

const FORM_VALUE = 'form/value';
const FORM_INVALID_FIELD = 'form/invalid-field';
const FORM_RESET_ERRORS = 'form/reset-errors';
const FORM_SEND_ERROR = 'form/send-error';

const initialState = {
  isFormSending: false,

  name: '',
  isNameInvalid: false,

  email: '',
  isEmailInvalid: false,

  message: '',
  isMessageInvalid: false,

  errorMessage: '',
};

const reducer = (state, action) => {
  switch (action.type) {
    case FORM_VALUE:
      return {
        ...state,
        [action.field]: action.value,
      };
    case FORM_INVALID_FIELD:
      return {
        ...state,
        [action.field]: true,
        isFormSending: false,
      };

    case FORM_RESET_ERRORS:
      return {
        ...state,
        isNameInvalid: false,
        isEmailInvalid: false,
        isMessageInvalid: false,
        isFormSending: true,
        errorMessage: '',
      };
    case FORM_SEND_ERROR:
      return {
        ...state,
        errorMessage: action.errorMessage,
        isFormSending: false,
      };
    default:
      return state;
  }
};

function Form() {
  const [state, dispatch] = useReducer(reducer, initialState);
  const {
    name,
    email,
    message,
    isNameInvalid,
    isEmailInvalid,
    isMessageInvalid,
    isFormSending,
    errorMessage,
  } = state;

  const dispatchInputValue = (field, value) =>
    dispatch({ type: FORM_VALUE, field, value });

  const dispatchInvalidForm = (field) => dispatch({ type: FORM_INVALID_FIELD, field });

  const resetErrors = () => dispatch({ type: FORM_RESET_ERRORS });

  const dispatchErrorMessage = (errorMessage) =>
    dispatch({ type: FORM_SEND_ERROR, errorMessage });

  const dataLayerPush = (data) => {
    const dataLayer = get(window, 'dataLayer', null);

    if (dataLayer) {
      dataLayer.push(data);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    resetErrors();

    let isFormValid = true;

    if (name.length < 2) {
      dispatchInvalidForm('isNameInvalid');
      isFormValid = false;
    }

    if (message.length < 2) {
      dispatchInvalidForm('isMessageInvalid');
      isFormValid = false;
    }

    if (email.length < 3 && !email.match(EMAIL_REGEX)) {
      dispatchInvalidForm('isEmailInvalid');
      isFormValid = false;
    }

    if (isFormValid) {
      // send form here

      const mailTemplate = {
        from_name: name,
        message_html: message,
        reply_to: email,
      };

      if (email === 'lozev.emil.05@gmail.com') {
        dataLayerPush({ event: 'contact-form-sent', success: true });
        navigate('/thank-you');
        return;
      }

      emailjs
        .send(
          'gmail',
          'website_form_submission',
          mailTemplate,
          'user_wJpUWwzlwCkKgcGmvFCbE',
        )
        .then(() => {
          dataLayerPush({ event: 'contact-form-sent', success: true });
          navigate('/thank-you');
        })
        .catch((e) => {
          console.error('FAILED...', e);
          dataLayerPush({ event: 'contact-form-sent', success: false });
          dispatchErrorMessage(e.text);
        });
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="js-state-error" />
      <div className="js-state-form">
        <div className="row">
          <div className="col-lg-6">
            <div className="form-group">
              <label htmlFor="fullName">* Your name</label>
              <input
                name="fullName"
                id="fullName"
                className={classnames('form-control', {
                  'is-invalid': isNameInvalid,
                })}
                value={name}
                onChange={(e) => dispatchInputValue('name', e.target.value)}
                data-required
              />
              <div className="invalid-feedback">Please enter your name</div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="form-group">
              <label htmlFor="email">* Email address</label>
              <input
                type="email"
                name="email"
                id="email"
                value={email}
                onChange={(e) => dispatchInputValue('email', e.target.value)}
                className={classnames('form-control', {
                  'is-invalid': isEmailInvalid,
                })}
                data-required
              />
              <div className="invalid-feedback">Please enter correct email address</div>
            </div>
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="message">* Please describe your question(s)?</label>
          <textarea
            rows="5"
            name="message"
            id="message"
            value={message}
            onChange={(e) => dispatchInputValue('message', e.target.value)}
            className={classnames('form-control', {
              'is-invalid': isMessageInvalid,
            })}
            data-required
          />
          <div className="invalid-feedback">Please enter your question</div>
        </div>
        <input
          type="hidden"
          className="g-recaptcha-response"
          name="g-recaptcha-response"
        />
        <div className="row">
          <div className="col-lg-6 mb-4 mb-lg-0">
            <p className="text-small text-grey">
              <strong>PLEASE NOTE:</strong> Communications sent to us via email or through
              our website are not secure and will not be regarded by us as confidential.
              Do not send proprietary and confidential information without first speaking
              with our attorney.
            </p>
          </div>
          <div className="offset-lg-3 col-lg-3 text-lg-right">
            <button
              disabled={isFormSending}
              type="submit"
              className="btn btn-secondary btn-block button-submit">
              SUBMIT
            </button>
          </div>
        </div>
      </div>
      {errorMessage && (
        <div className="text-center js-state-success mb-4">
          <p className="text-danger mt-5">
            <b>We are facing an issue trying to send your email:</b> {errorMessage}
          </p>
        </div>
      )}
    </form>
  );
}

Form.propTypes = {};

export default Form;

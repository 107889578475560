import React from 'react';
import CalendlyButton from './calendly-button';
import Form from './form';

function FormCalendly() {
  return (
    <div id="scrollToTop" className="section pt-5 pb-5 pt-md-10">
      <div className="container container-border-pale pt-lg-8 pb-lg-8 is-top">
        <div className="row">
          <div className="offset-lg-1 col-lg-10 z-index-1">
            <div className="js-state-form">
              <h1 className="text-hero mb-4 mb-md-6">
                Schedule a<br />
                Consultation
              </h1>
              <p className="text-body mb-7">
                We offer confidential 30 minute and 1 hour video/telephone consultations.
                All consultations are conducted by our principal attorney, Adrian Pandev.
                During your consultation, Adrian will conduct an in-depth analysis of your
                case, review relevant documents, and provide you with a custom-tailored
                strategy for your case.
              </p>
              <h3 className="text-mega text-contact-schedule mb-4">
                Schedule a consultation by clicking the button below:
              </h3>
              <div className="mb-7">
                <CalendlyButton />
              </div>
              <hr />
              <h3 className="text-mega text-contact-schedule-color mt-7 mb-5">
                - OR
                <span className="text-contact-schedule"> send us a message:</span>
              </h3>
            </div>
            <Form />
          </div>
        </div>
      </div>
    </div>
  );
}

FormCalendly.propTypes = {};

export default FormCalendly;
